<template>
  <div class="h-screen flex w-full vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-2/5 sm:m-0 m-4">
      <VxCard>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">

            <div class="vx-col sm:w-full md:w-full lg:w-full d-theme-dark-bg">
              <div class="p-8 login-tabs-container">
                <div class="vx-card__title mb-4">
                  <div class="vx-logo mx-auto flex items-center justify-center">
                    <Logo class="w-32 mr-3 fill-current text-primary"/>
<!--                    <span class="vx-logo-text text-primary">DAFIS</span>-->
                  </div>
                  <p class="mt-2 justify-center flex text-primary">{{ description }}</p>
                  <vs-divider></vs-divider>
                  <h4 class="mb-4">Login</h4>
                  <p>Selamat datang, mohon login untuk melanjutkan.</p>
                </div>

                <vs-alert color="danger" active="true" class="my-3" v-if="isSessionExpired">
                  Sesi Anda telah expired, silahkan login kembali untuk melanjutkan.
                </vs-alert>

                <ValidationErrors :errors="errors"/>

                <div>
                  <vs-input
                    type="text"
                    icon="icon icon-user"
                    icon-pack="feather"
                    label="Username"
                    placeholder="Username"
                    v-model="username"
                    @keyup.enter="login"
                    class="w-full"/>

                  <vs-input
                    type="password"
                    icon="icon icon-lock"
                    icon-pack="feather"
                    label="Password"
                    placeholder="Password"
                    v-model="password"
                    @keyup.enter="login"
                    class="w-full mt-3"/>

                  <vs-button id="btnLogin" :disabled="isLoading" class="w-full text-center mt-10" @click="login">
                    <span v-if="isLoading" class="animate-pulse">Mohon tunggu...</span>
                    <span v-if="!isLoading">Login</span>
                  </vs-button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </VxCard>
    </div>
  </div>
</template>

<script>
import Logo from '@/views/components/logo/Logo'
import AuthRepository from '@/repositories/general/auth-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'

export default {
  name: 'Login',
  components: {
    ValidationErrors,
    Logo
  },
  data () {
    return {
      username: '',
      password: '',
      errors: null,
      isLoading: false
    }
  },
  computed: {
    isSessionExpired () {
      return !!localStorage.getItem('isSessionExpired')
    },
    description () {
      return process.env.VUE_APP_PROJECT_DESCRIPTION
    }
  },
  mounted () {
    localStorage.removeItem('isSessionExpired')
  },
  methods: {
    login () {
      this.errors = null
      this.isLoading = true

      AuthRepository.getCsrfCookie()
        .then(response => {
          AuthRepository.login(this.username, this.password)
            .then(response => {
              this.$store.commit('auth/SET_USER_DATA', response.data.data.userData)
              this.$store.commit('system/SET_SYSTEM_DATA', response.data.data.systemData)
              if (this.isSessionExpired) {
                this.$router.push(localStorage.getItem('previousPath'))
                localStorage.removeItem('previousPath')
              } else {
                this.$router.push({ name: 'dashboard' })
              }
            })
            .catch(error => {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors
              } else if (error.response.status === 403) {
                this.errors = [error.response.data.message]
              } else {
                this.notifyError('Terjadi kesalahan.')
              }
              this.isLoading = false
            })
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    }
  }
}
</script>
